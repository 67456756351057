<template>
  <div class="Plain is-flex is-column is-width-full">
    <slot />
  </div>
</template>

<script>
/**
 * Generates a plain wrapper, without any markup
 * @module Plain
 */
export default {
  name: 'Plain'
}
</script>
